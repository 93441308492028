//地址数据
//https://preapi.ectcm.com:8070/
//https://api.ectcm.com:8070/
export default {
    login: '/api/user/login',
    logout: '/api/user/signOut',
    register: '/api/user/clientRegister',
    // 检查登录账号是否和后台重复
    searchOSClientByPhone: 'api/user/searchOSClientByPhone',
    getVCode: '/api/vcode/getVCode',
    resetPassword: '/api/user/resetPwd',
    updatePassword: '/api/user/updatePwd',
    //获取用户信息
    getUserInfo: '/api/user/getClientById',
    //我參加的學會活動
    getMyActivitys: '/api/activity/getMyJoined',
    // 获取就诊记录
    getRecordList: '/api/visitrecord/getUserVisitRecord',
    // 诊症(病例)详情 PC
    getRecordDetail: '/api/visitrecord/getVisitDetail',
    // 诊症(病例)详情 mobile
    getMobileRecordDetail: '/api/docprescription/getPrecriptiontByClientRegisterId',
    //我的预约
    getBookingList: '/api/sckedule/getScheduleListByClientId',
    //取消预约
    cancelBooking: '/api/sckedule/deleteSchedule',
    //医疗计划列表
    getPlanCardList: '/api/visitrecord/getServiceCardsMedicalPlan',
    //服务卡列表
    getServiceCardList: '/api/visitrecord/getServiceCardsMedicalPlan',
    //医疗计划详情
    getPlanCardDetail: '/api/visitrecord/getMedicalPlanUseDetail',
    //服务卡详情
    getServiceCardDetail: '/api/visitrecord/getClientServiceCardDetail',
    //添加咨詢
    addQuestion: '/api/ask/addAsk',
    //獲取文章列表
    getNewsList: '/api/news/getWebNewsList',
    //获取热门文章
    getHotNewsList: '/api/news/getHotNewsList',
    //资讯详情
    getNewsDetail: '/api/news/getNewsDetail',
    //文章分類
    getNewsCategorys: '/api/news/getNewsCategory',
    //根据医师id获取诊所列表
    getClinicsByDoctor: '/api/common/getClinicListByDocGlobalId',
    //发布资讯
    addNews: '/api/news/addNews',
    //图片上传
    uploadImage: '/api/file/uploadImage',
    //文件上传
    uploadFile: '/api/file/uploadFile',
    //修改用户信息
    updateUserInfo: '/api/user/updateClientInfo',
    //获取地区
    getAreaList: '/api/area/getAreaByParentId',
    //获取问答列表
    getAskListByCategory: '/api/ask/getWebAskList',
    //我的問答列表
    getMyAskList: '/api/ask/getMyAskList',
    //問答詳情
    getAskDetail: '/api/ask/getAskDetail',
    //获取学会列表
    getAssociationList: '/api/association/getAssociationListByReviewd',
    //获取学会活动列表
    getActivityList: '/api/activity/getWebActivityList',
    //问答分类
    getAskCategorys: '/api/ask/getIllnessCategory',

    //搜索医师
    getDoctorList: '/api/doctorclinic/searchDoctor',

    //获取医师回复过的问题
    getDoctorAskList: '/api/ask/getWebAskListByDocGlobalID',

    //获取医师回答的问题
    getDoctorNewsList: '/api/news/getNewsListByGlobalUserId',
 
    //获取医师工作日期
    getDoctorWeekWorkDate: '/api/sckedule/getDoctorWeekWorkDate',

    //获取医师工作时刻
    getDoctorWeekWorkTimes: '/api/sckedule/getDoctorWorkTimeSection',

    //提交预约
    addBooking: '/api/sckedule/saveSchedule',

    //问答之星（医师排行）
    getTopAskDoctors: '/api/ask/getTopAnswer',

    //使用中医在线的诊所
    getHomeClinics: '/api/clinic/getRandomClinicList',

    //获取诊所列表
    getClinicList: '/api/clinic/searchClinic',

    //诊所详情
    getClinicDetail: '/api/clinic/getClinicDetailByClinicId',

    //获取诊所医师列表
    getDoctorsByClinic: '/api/doctorclinic/getDoctorByClinicID',
    
    //獲取醫師詳情
    getDoctorDetail: '/api/doctorclinic/getDocDetailByGlobalUserID',

    //獲取醫師所在的診所
    getClinicListByDoctor: '/api/clinic/getClinicListByGlobalUserID',

    //儿童健康测试
    childTest: '/api/children/calcChildHealth',

    //诊所注册
    createOSCount: '/api/os/doctorClinicSignup',

    //上传凭证
    addCertificate: '/api/activity/updateRegAttachment',

    //获取顾客和用户预约数
    getBookingCount: '/api/common/getSystemStatistic',

    //提交反馈
    addFeedback: '/api/clientfeedback/submitWebFeedback',

    //招聘职位列表
    getJobList: '/api/job/queryJobList',

    //招聘职位详情
    getJobDetail: '/api/job/queryJobDetail',

    //地区详情
    getAreaDetail: '/api/area/getSigleArea',

    getEmploymentType: '/api/codetable/getEmploymentType',

    getQualification: '/api/codetable/getQualification',
    
    getWorkYears: '/api/codetable/getWorkYears',

    //新增简历
    addResume: '/api/resume/add',
    //修改公开状态
    changeResumeStatus: '/api/resume/changeOpenStatus',
    //删除数据
    deleteResume: '/api/resume/delete',
    //获取客户简历
    getResume: '/api/resume/getClientResume',
    //修改简历
    updateResume: '/api/resume/modify',
    // 查询投递列表
    queryDeliveries: '/api/job/delivery/queryDeliveries',
    // 投递简历
    delivery: '/api/job/delivery/delivery',
}   