<script>
const friendlyLinks = [
    {
        title: '海天濃縮中藥',
        url: 'http://www.hoitin.hk/zn/',
    },
    {
        title: '中國中醫藥報',
        url: 'http://www.cntcm.com.cn/',
    },
    {
        title: '香港中醫學會',
        url: 'http://www.chinesemedicinehka.com/',
    },
    {
        title: '中國中醫科學院',
        url: 'http://www.cacms.ac.cn/',
    },
    {
        title: '香港註冊中醫學會',
        url: 'http://www.hkrcmp.org/',
    },
    {
        title: '香港中醫藥管理委員會',
        url: 'http://www.cmchk.org.hk/',
    },
    {
        title: '香港大學中醫藥學院',
        url: 'http://www.scm.hku.hk/',
    },
    {
        title: '香港中文大學中醫學院',
        url: 'https://scm.cuhk.edu.hk/zh-tw/',
    },
    {
        title: '香港浸會大學中醫學院',
        url: 'https://scm.hkbu.edu.hk/zh_hk.html',
    }
]
export default {
    friendlyLinks
}
</script>
