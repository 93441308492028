import * as types from '@/store/mutation-types.js';
import api from "@/api/association";

export default {
    state: {
    },
    getters: {
    },
    actions: {
        getAssociationList({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getAssociationList(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getActivityList({commit}, data) {
            return new Promise((resolve, reject) => {
                api.getActivityList(data).then(rs => {
                  if(rs['code'] == 200){
                    resolve(rs.data);
                  } else {
                    reject(rs.msg)
                  }
                }).catch(error => {
                  reject(error)
                })
              }
            )
        },
        addCertificate({commit}, data) {
          return new Promise((resolve, reject) => {
              api.addCertificate(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            }
          )
      },
    },
    mutations: {
        
    }
};
