import * as types from '@/store/mutation-types.js';
import api from "@/api/question";

export default {
    state: {
      askCategorys: null,
      topDoctors: null,
    },
    getters: {
      askCategorys: (state) => {
        const { askCategorys } = state
        return askCategorys
      },
      topDoctors: (state) => {
        const { topDoctors } = state
        return topDoctors
      },
    },
    actions: {
        addQuestion({commit}, data) {
          return new Promise((resolve, reject) => {
              api.addQuestion(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getAskListByCategory({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getAskListByCategory(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getAskCategorys({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getAskCategorys(data).then(rs => {
                if(rs['code'] == 200){
                  commit(types.SET_ASK_CATEGORY, rs.data)
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getTopAskDoctors({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getTopAskDoctors(data).then(rs => {
                if(rs['code'] == 200){
                  // commit(types.SET_TOP_DOCTORS, (rs.data || []).splice(0, 10))
                  resolve((rs.data || []).splice(0, 10));
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getMyAskList({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getMyAskList(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getAskDetail({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getAskDetail(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
    },
    mutations: {
      [types.SET_ASK_CATEGORY](state, askCategorys) {
          state.askCategorys = askCategorys;
      },
      [types.SET_TOP_DOCTORS](state, topDoctors) {
        state.topDoctors = topDoctors;
    },
  }
};
