/**
 * 医师
 * add 2020-10-16 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    getDoctorList: (data) => Request.post(address.getDoctorList, data),
    getDoctorWeekWorkDate: (data) => Request.get(address.getDoctorWeekWorkDate, data),
    getDoctorWeekWorkTimes: (data) => Request.get(address.getDoctorWeekWorkTimes, data),
    addBooking: (data) => Request.post(`${address.addBooking}?clientId=${data.clientId}&doctorId=${data.doctorId}&scheduleDate=${data.scheduleDate}&scheduleID=${data.scheduleID}&sourceType=${data.sourceType}&note=${data.note}`, null),
    getDoctorsByClinic: (data) => Request.get(address.getDoctorsByClinic, data),
    getDoctorDetail: (data) => Request.get(address.getDoctorDetail, data),
    getClinicListByDoctor: (data) => Request.get(address.getClinicListByDoctor, data),
    getDoctorAskList: (data) => Request.get(address.getDoctorAskList, data),
    getDoctorNewsList: (data) => Request.get(address.getDoctorNewsList, data),
    getBookingCount:(data) => Request.get(address.getBookingCount, data),
}