/**
 * 用户操作接口调用
 * add 2020-08-18 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    login: (data) => Request.post(address.login, data),
    logout: (clientId, token) => Request.post(`${address.logout}?clientId=${clientId}&token=${token}`),
    register: (data) => Request.post(address.register, data),
    searchOSClientByPhone: (phone) => Request.get(`${address.searchOSClientByPhone}?phone=${phone}`, null),
    getVCode: (data) => Request.post(`${address.getVCode}?codeType=${data.codeType}&phoneNumber=${data.phoneNumber}`, null),
    resetPassword: (data) => Request.post(`${address.resetPassword}?codeKey=${data.codeKey}&newPwd=${data.newPwd}&numcode=${data.numcode}&phone=${data.phone}`, null),
    updatePassword: (data) => Request.post(`${address.updatePassword}?clientId=${data.clientId}&newPwd=${data.newPwd}&oldPwd=${data.oldPwd}`, null),
    getUserInfo: (data) => Request.get(`${address.getUserInfo}?clientid=${data.clientId}`, null),

    getMyActivitys: (data) => Request.get(address.getMyActivitys, data),

    //创建后台账号
    createOSCount:(data) => Request.post(address.createOSCount, data),

    //就診記錄
    getRecordList: (data) => Request.get(address.getRecordList, data),
    //病例详情 pc
    getRecordDetail: (data) => Request.get(address.getRecordDetail, data),
    //病例详情 mobile
    getMobileRecordDetail: (data) => Request.get(address.getMobileRecordDetail, data),
    //我的预约
    getBookingList: (data) => Request.get(`${address.getBookingList}?clientId=${data.clientId}&pageNo=${data.pageNo}&pageSize=${data.pageSize}`, null),
    //取消预约
    cancelBooking: (data) => Request.post(`${address.cancelBooking}?scheduleID=${data.scheduleID}`, null),
    //医疗计划列表
    getPlanCardList: (data) => Request.get(`${address.getPlanCardList}?clientID=${data.clientId}`, null),

    //服务卡列表
    getServiceCardList: (data) => Request.get(`${address.getServiceCardList}?clientID=${data.clientId}`, null),

    //医疗计划详情
    getPlanCardDetail: (data) => Request.get(`${address.getPlanCardDetail}?clientid=${data.clientId}&medicalplanitemid=${data.medicalplanitemid}`, null),

    // 服务卡消费详情
    getServiceCardDetail: (data) => Request.get(`${address.getServiceCardDetail}?clientid=${data.clientId}&clientservicecardid=${data.clientservicecardid}`, null),

    //图片上传，imgCategory: 图像类型：1发现医生 2健康记录 3修改头像 4验证身份证 (暂时只有发现医生和健康记录1,2), 5.添加咨询，6.文章图片, 7.学会报名附件
    uploadImage:(data) => Request.file(`${address.uploadImage}?imgCategory=${data.imgCategory}`, data.form),

    uploadFile:(data) => Request.file(address.uploadFile, data),

    updateUserInfo:(data) => Request.post(address.updateUserInfo, data),

    getAreaList:(data) => Request.get(address.getAreaList, data),

    addFeedback:(data) => Request.post(`${address.addFeedback}?contact=${data.contact}&msgTxt=${data.msgTxt}`, null),

    getAreaDetail:(data) => Request.get(`${address.getAreaDetail}?areaLevel1Id=${data.areaLevel1Id}&areaLevel2Id=${data.areaLevel2Id}&langcode=${data.langcode}`, null),

    //新增简历
    addResume: (data) => Request.post(address.addResume, data),
    //修改简历状态
    changeResumeStatus: (data) => Request.post(address.changeResumeStatus, data),
    //删除简历
    deleteResume: (data) => Request.post(address.deleteResume, data),
    //查询简历
    getResume: (data) => Request.post(address.getResume, data),
    //修改简历
    updateResume: (data) => Request.post(address.updateResume, data),
    // 查询投递列表
    queryDeliveries: (data) => Request.post(address.queryDeliveries, data),
    // 投递简历
    delivery: (data) => Request.post(address.delivery, data),
}