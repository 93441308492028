import axios from 'axios';
import env from '@/config/env';
import store from '@/store'

const MOCKURL = ''; // mock数据地址

/**
 * 自定义Axios实例
 */
const AJAX = axios.create({
    baseURL: process.env.VUE_APP_API,
    timeout: 800000,
    withCredentials: env.credential
});

// 添加请求拦截器
AJAX.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // if (process.env.NODE_ENV === 'development') {
    //     config.url = `http://${location.host}` + config.url; // 自定义反向代理，可以在demo阶段打开看下请求效果
    // }

    if (store.getters.token) {
       config.headers['token'] = store.getters.token
    }

    // A-android
    // I-IOS
    // F-前端网页
    // B-中医在线后台
    config.headers['sourceType'] = 'F'
    return config;
}, function (error) {
    // 对请求错误做些什么
    
    return Promise.reject(error);
});

// 添加响应拦截器
AJAX.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    var rs = response.data
    if(rs.code == 401) {
        window.location.href="/login"
        return {}
    }
    return rs;
}, function (error) {
    // 对响应错误做点什么，比如400、401、402等等
    return Promise.reject(error);
});

// 定义对外Get、Post、File请求
export default {
    get(url, param = {}, headers = {}) {
        if(location.href.indexOf('/user/') != -1 && !store.getters.token) {
            return new Promise((reslove, reject) => {
                reslove({'code': 211, msg: '未登錄，請先登錄'})
            })
        }
        return AJAX.get(url, {
            params: param,
            headers,
        })
    },
    post(url, param = null, headers = {}) {
        if(location.href.indexOf('/user/') != -1 && !store.getters.token) {
            return new Promise((reslove, reject) => {
                reslove({'code': 211, msg: '未登錄，請先登錄'})
            })
        }
        return AJAX.post(url, param, headers)
    },
    put(url, param = null, headers = {}) {
        return AJAX.put(url, param, {
            headers,
        })
    },
    file(url, param = null, headers = {}) {
        return AJAX.post(url, param, {
            headers: Object.assign({
                'Content-Type': 'multipart/form-data'
            }, headers)
        })
    },
    delete(url, param = null, headers = {}) {
        return AJAX.delete(url, {
            param,
            headers: Object.assign({
                'Content-Type': 'multipart/form-data'
            }, headers)
        })
    }
}
