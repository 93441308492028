import * as types from '@/store/mutation-types.js';
import api from "@/api/clinic";

export default {
    state: {
      homeClinicList: null, 
    },
    getters: {
      homeClinicList: (state) => {
        const { homeClinicList } = state
        return homeClinicList
      }
    },
    actions: {
      getClinicsByDoctor({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getClinicsByDoctor(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getHomeClinics({commit}, data) {
            return new Promise((resolve, reject) => {
                api.getHomeClinics(data).then(rs => {
                  if(rs['code'] == 200){
                    resolve(rs.data);
                    var dataList = rs.data.list || []
                    dataList = dataList.length == 15 ? dataList : []
                    var list = []
                    for(var i=0; i<dataList.length;i++) {
                        if(i%3 == 0) {
                           list.push([])
                        }
                        list[list.length -1].push(dataList[i])
                    }
                    // commit(types.SET_HOME_CLINICLIST, list)
                  } else {
                    reject(rs.msg)
                  }
                }).catch(error => {
                  reject(error)
                })
              })
          },
          getClinicList({commit}, data) {
            return new Promise((resolve, reject) => {
                api.getClinicList(data).then(rs => {
                  if(rs['code'] == 200){
                    resolve(rs.data);
                  } else {
                    reject(rs.msg)
                  }
                }).catch(error => {
                  reject(error)
                })
              })
          },
          getClinicDetail({commit}, data) {
            return new Promise((resolve, reject) => {
                api.getClinicDetail(data).then(rs => {
                  if(rs['code'] == 200){
                    resolve(rs.data);
                  } else {
                    reject(rs.msg)
                  }
                }).catch(error => {
                  reject(error)
                })
              })
          },
    },
    mutations: {
      [types.SET_HOME_CLINICLIST](state, homeClinicList) {
        state.homeClinicList = homeClinicList;
      },
    }
};
