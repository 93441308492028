import * as types from '@/store/mutation-types.js';
import api from "@/api/news";

export default {
    state: {
      newsCategorys: null,
      homeNewsList: null,
    },
    getters: {
      homeNewsList: (state) => {
        const { homeNewsList } = state
        return homeNewsList
      },
      newsCategorys: (state) => {
        const { newsCategorys } = state
        return newsCategorys
      }
    },
    actions: {
        addNews({commit}, data) {
          return new Promise((resolve, reject) => {
              api.addNews(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getHotNewsList({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getHotNewsList(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getNewsList({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getNewsList(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data)
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        getNewsDetail({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getNewsDetail(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getNewsCategorys({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getNewsCategorys(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
    },
    mutations: {
      [types.SET_NEWS_CATEGORYS](state, newsCategorys) {
        state.newsCategorys = newsCategorys;
      },
      [types.SET_HOME_NEWSLIST](state, homeNewsList) {
        state.homeNewsList = homeNewsList;
      },
    }
};
