/**
 * 咨詢
 * add 2020-08-18 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    addQuestion: (data) => Request.post(address.addQuestion, data),
    getAskListByCategory: (data) => Request.get(address.getAskListByCategory, data),
    getMyAskList: (data) => Request.get(address.getMyAskList, data),
    getAskCategorys: (data) => Request.get(address.getAskCategorys, data),
    getTopAskDoctors: (data) => Request.get(address.getTopAskDoctors, data),
    getAskDetail: (data) => Request.get(address.getAskDetail, data),
}