import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locale'
import constants from './Constants'
import filters from './filters'
import * as VueGoogleMaps from 'vue2-google-maps'
import { FormModel,Radio, Form, Row, Col, Button, InputNumber, Select, Modal, Cascader, Popconfirm, ConfigProvider, Spin, message, notification, Popover, Carousel, Dropdown, Menu, DatePicker, TimePicker, Input } from 'ant-design-vue'
// 移动端适配
import 'lib-flexible/flexible.js'
import { MapElementFactory } from 'vue2-google-maps'
import myToast from './common/myToast'
import '../node_modules/viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

import {
  Swiper as SwiperClass,
  Pagination,   //使用那个组件就在这里面添加
  Mousewheel,
  Autoplay,
  Navigation,
  EffectFade,
} from "swiper";
import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";
import 'swiper/swiper-bundle.css'
//这里面对应上面进行添加  
SwiperClass.use([Pagination, Mousewheel, Autoplay, Navigation, EffectFade]);
Vue.use(getAwesomeSwiper(SwiperClass));

Vue.component(Spin.name, Spin)
Vue.component(Popover.name, Popover)
Vue.component(Carousel.name, Carousel)
Vue.component(Dropdown.name, Dropdown)
Vue.component(Menu.name, Menu)
Vue.component(Menu.SubMenu.name, Menu.SubMenu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Menu.ItemGroup.name, Menu.ItemGroup)
Vue.component(Menu.Divider.name, Menu.Divider)
Vue.component(DatePicker.name, DatePicker)
Vue.component(TimePicker.name, TimePicker)
Vue.component(Input.name, Input)
Vue.component(ConfigProvider.name, ConfigProvider)
Vue.component(Popconfirm.name, Popconfirm)
Vue.component(Cascader.name, Cascader)
Vue.component(DatePicker.RangePicker.name, DatePicker.RangePicker)
Vue.component(Radio.name, Radio)
Vue.component(Radio.Group.name, Radio.Group)
Vue.use(Select)
Vue.use(FormModel)
Vue.use(Form)
Vue.use(Row)
Vue.use(Col)
Vue.use(InputNumber)
Vue.use(Button)
Vue.use(Modal)
Vue.use(Viewer)
Vue.prototype.constants = constants;
Vue.prototype.$message = message
Vue.prototype.$notification = notification
message.config({
  duration: 2,
})

Vue.prototype._toast = myToast
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDyFnfIrE73Sd9c8tA-d6VjREm7TiQoy-g',
    libraries: 'places', // This is required if you use the Autocomplete plugin

    region: 'CN',
    language: 'zh-HK',
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
  }
})

Vue.component('ground-overlay', MapElementFactory({
  mappedProps: {
    'opacity': {}
  },
  props: {
    'source': { type: String },
    'bounds': { type: Object },
  },
  events: ['click', 'dblclick'],
  name: 'groundOverlay',
  ctr: () => google.maps.GroundOverlay,
  ctrArgs: (options, { source, bounds }) => [source, bounds, options],
}))

Vue.config.productionTip = false

// 如果是非线上环境，加载 VConsole（移动端适用）
// if (process.env.NODE_ENV !== 'production') {
//     var VConsole = require('vconsole/dist/vconsole.min.js');
//     var vConsole = new VConsole();
// }

// 全局过滤器
Object.keys(filters).forEach(filterName => {
  Vue.filter(filterName, filters[filterName])
})

// if (!window.sessionStorage.length) {
//   window.localStorage.setItem('getSessionStorageData', Date.now())
// }
// window.addEventListener('storage', (event) => {
//   if (event.key === 'getSessionStorageData') {
//     console.log('window.sessionStorage', window.sessionStorage)
//     window.localStorage.setItem('sessionStorageData', JSON.stringify(window.sessionStorage))
//     window.localStorage.removeItem('sessionStorageData')
//   }
//   if (event.key === 'sessionStorageData' && !window.sessionStorage.length) {
//     const data = JSON.parse(event.newValue)
//     for (let key in data) {
//       window.sessionStorage.setItem(key, data[key])
//     }
//   }
//   // ========== 加下面這段 ==========
//   if (event.key === 'logout') {
//     // 接收到logout事件時，進行sessionStorage的清除與頁面reload
//     window.sessionStorage.clear()
//     window.location.reload()
//   }
// })

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
}).$mount('#app')