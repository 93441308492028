import user from './user';
import question from './question';
import jobs from './jobs';
import news from './news';
import clinic from './clinic'
import association from './association'
import doctor from './doctor'
import children from './children'

export default {
    user,
    jobs,
    question,
    news,
    clinic,
    association,
    doctor,
    children,
};
