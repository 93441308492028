import * as types from '@/store/mutation-types.js';
import api from "@/api/doctor";

export default {
    state: {
    },
    getters: {
    },
    actions: {
        getDoctorList({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorList(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getDoctorWeekWorkDate({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorWeekWorkDate(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getDoctorWeekWorkTimes({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorWeekWorkTimes(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        addBooking({commit}, data) {
          return new Promise((resolve, reject) => {
              api.addBooking(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getDoctorsByClinic({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorsByClinic(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getDoctorDetail({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorDetail(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getClinicListByDoctor({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getClinicListByDoctor(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getDoctorAskList({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorAskList(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getDoctorNewsList({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getDoctorNewsList(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getBookingCount({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getBookingCount(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
    },
    mutations: {
        
    }
};
