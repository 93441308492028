/**
 * 资讯
 * add 2020-08-18 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    childTest: (data) => Request.post(address.childTest, data),
}