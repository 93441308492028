import * as types from '@/store/mutation-types.js';
import api from "@/api/jobs";

export default {
  state: {
    jobJson: null,
  },
  getters: {
    jobJson: (state) => {
      const { jobJson } = state
      return jobJson
    },
  },
  actions: {
    getJobList({commit}, data) {
      return new Promise((resolve, reject) => {
          api.getJobList(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      getJobDetail({commit}, data) {
        return new Promise((resolve, reject) => {
            api.getJobDetail(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
      },
      getEmploymentType({commit}, data) {
        return new Promise((resolve, reject) => {
            api.getEmploymentType(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
      },
      getQualification({commit}, data) {
        return new Promise((resolve, reject) => {
            api.getQualification(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
      },
      getWorkYears({commit}, data) {
        return new Promise((resolve, reject) => {
            api.getWorkYears(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
      },
  },
  mutations: {
    [types.SET_JOB_JSON](state, jsonJson) {
      state.jsonJson = jsonJson;
    },
  }
};
