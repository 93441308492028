import * as types from '@/store/mutation-types.js';

export default {
    [types.SET_USER_INFO](state, userInfo) {
        state.userInfo = userInfo;
    },
    [types.SET_TOKEN](state, token) {
        state.token = token;
    },
    [types.SET_CLIENTID](state, clientId) {
        state.clientId = clientId;
    },
    [types.SET_ASK_CATEGORY](state, askCategorys) {
        state.askCategorys = askCategorys;
    },
    [types.SET_TOP_DOCTORS](state, topDoctors) {
        state.topDoctors = topDoctors;
    },
}
