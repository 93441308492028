/**
 * 学会、活动
 */
import Request from '../common/request';
import address from './address'

export default {
    getAssociationList: (data) => Request.get(address.getAssociationList, data),
    getActivityList: (data) => Request.get(address.getActivityList, data),
    addCertificate: (data) => Request.post(`${address.addCertificate}?activityregid=${data.activityregid}&attachment=${data.attachment}&paytime=${data.paytime}`, null),
}