import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist'
import mutations from './mutations'
import actions from './actions'
import home from './home';

Vue.use(Vuex);

let modules = {
    ...home,
};

//使用vuex-persist插件解决页面刷新后store为空的问题
const vuexLocal = new VuexPersistence({
    storage: window.sessionStorage
})
export default new Vuex.Store({
    state: {
        groups: [1],
        title: '',
    },
    modules,
    actions,    // 根级别的 action
    mutations,  // 根级别的 mutations
    // 根级别的 getters
    getters: {
        getGroups(state) {
            return state.groups
        }
    },
    plugins: [vuexLocal.plugin]
})
