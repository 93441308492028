import * as types from '@/store/mutation-types.js';
import api from "@/api/children";

export default {
    state: {
    },
    getters: {
    },
    actions: {
      childTest({commit}, data) {
          return new Promise((resolve, reject) => {
              api.childTest(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
    },
    mutations: {
    }
};
