/**
 * 资讯
 * add 2020-08-18 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    getClinicsByDoctor: (data) => Request.get(`${address.getClinicsByDoctor}?doctorglobaluserid=${data.doctorglobaluserid || 0}`, null),
    //获取首页诊所显示
    getHomeClinics: (data) => Request.get(address.getHomeClinics, data),
    //诊所列表
    getClinicList: (data) => Request.post(address.getClinicList, data),
    //诊所详情
    getClinicDetail: (data) => Request.get(address.getClinicDetail, data),
}