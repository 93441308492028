export default {
    warning: (that, msg) => {
        if(/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent)) {
            that.$toast({message: msg});
        } else {
            that.$message.warning(msg)
        }
    },
    info: (that, msg) => {
        if(/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent)) {
            that.$toast({message: msg});
        } else {
            that.$message.info(msg)
        }
    },
    success: (that, msg) => {
        if(/Android|webOS| iPhone | iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i.test(navigator.userAgent)) {
            that.$toast({message: msg});
        } else {
            that.$message.success(msg)
        }
    }
}