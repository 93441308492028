import * as types from '@/store/mutation-types.js';
import api from "@/api/user";
import store from '@/store'
export default {
    // namespaced: true,
    state: {
        token: null,
        userInfo: null,
        clientId: null,
    },
    getters: {
        userInfo: (state) => {
            const { userInfo } = state
            return JSON.parse(userInfo)
        },
        token: (state) => {
            const { token } = state
            return token
        },
        clientId: (state) => {
          const { clientId } = state
          return clientId
        }
    },
    actions: {
      logout({commit}, clientId, token) {
          api.logout(clientId, token)
          commit(types.SET_TOKEN, null)
          commit(types.SET_USER_INFO, null)
          commit(types.SET_CLIENTID, null)
      },
      login({commit}, userInfo) {
          return new Promise((resolve, reject) => {
              api.login(userInfo).then(rs => {
                if(rs['code'] == 200){
                  commit(types.SET_TOKEN, rs.token)
                  commit(types.SET_USER_INFO, JSON.stringify(rs.data))
                  commit(types.SET_CLIENTID, rs.data.clientid)
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        register({commit}, userInfo) {
          return new Promise((resolve, reject) => {
              api.register(userInfo).then(rs => {
                if(rs['code'] == 200){
                  resolve();
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        searchOSClientByPhone({commit}, phone) {
          return new Promise((resolve, reject) => {
              api.searchOSClientByPhone(phone).then(rs => {
                if(rs['code'] == 200){
                  var list = [];
                  try {
                    list = rs['data'];
                  } catch(_){}
                  resolve(list);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getVCode({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getVCode(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        resetPassword({commit}, data) {
          return new Promise((resolve, reject) => {
              api.resetPassword(data).then(rs => {
                if(rs['code'] == 200){
                  resolve();
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        updatePassword({commit}, data) {
          return new Promise((resolve, reject) => {
              api.updatePassword(data).then(rs => {
                if(rs['code'] == 200){
                  resolve();
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getUserInfo({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getUserInfo(data).then(rs => {
                if(rs['code'] == 200){
                  resolve();
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        getMyActivitys({commit}, data) {
          return new Promise((resolve, reject) => {
              api.getMyActivitys(data).then(rs => {
                if(rs['code'] == 200){
                  resolve(rs.data);
                } else {
                  reject(rs.msg)
                }
              }).catch(error => {
                reject(error)
              })
            })
        },
        //就診記錄
        getRecordList({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getRecordList(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        getRecordDetail({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getRecordDetail(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        getMobileRecordDetail({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getMobileRecordDetail(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        //我的预约
        getBookingList({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getBookingList(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        //取消预约
        cancelBooking({commit}, data) {
          return new Promise((resolve, reject) => {
            api.cancelBooking(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        //医疗计划列表
        getPlanCardList({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getPlanCardList(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        //服务卡列表
        getServiceCardList({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getServiceCardList(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        getPlanCardDetail({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getPlanCardDetail(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        getServiceCardDetail({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getServiceCardDetail(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        uploadImage({commit}, data) {
          return new Promise((resolve, reject) => {
            api.uploadImage(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        uploadFile({commit}, data) {
          return new Promise((resolve, reject) => {
            api.uploadFile(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        updateUserInfo({commit}, data) {
          return new Promise((resolve, reject) => {
            api.updateUserInfo(data).then(rs => {
              if(rs['code'] == 200){
                commit(types.SET_USER_INFO, JSON.stringify({...store.getters.userInfo, ...data}))
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        getAreaList({commit}, data) {
          return new Promise((resolve, reject) => {
            api.getAreaList(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        createOSCount({commit}, data) {
          return new Promise((resolve, reject) => {
            api.createOSCount(data).then(rs => {
              if(rs['code'] == 200){
                resolve(rs.data);
              } else {
                reject(rs.msg)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
      //添加反馈
      addFeedback({commit}, data) {
        return new Promise((resolve, reject) => {
          api.addFeedback(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      getAreaDetail({commit}, data) {
        return new Promise((resolve, reject) => {
          api.getAreaDetail(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      //新增简历
      addResume({commit}, data) {
        return new Promise((resolve, reject) => {
          api.addResume(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      //修改简历状态
      changeResumeStatus({commit}, data) {
        return new Promise((resolve, reject) => {
          api.changeResumeStatus(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      //删除简历
      deleteResume({commit}, data) {
        return new Promise((resolve, reject) => {
          api.deleteResume(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      //查询简历
      getResume({commit}, data) {
        return new Promise((resolve, reject) => {
          api.getResume(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      //修改简历
      updateResume({commit}, data) {
        return new Promise((resolve, reject) => {
          api.updateResume(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 查询投递列表
      queryDeliveries({commit}, data) {
        return new Promise((resolve, reject) => {
          api.queryDeliveries(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      // 投递简历
      delivery({commit}, data) {
        return new Promise((resolve, reject) => {
          api.delivery(data).then(rs => {
            if(rs['code'] == 200){
              resolve(rs.data);
            } else {
              reject(rs.msg)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
    },
    mutations: {
      [types.SET_USER_INFO](state, userInfo) {
          state.userInfo = userInfo;
      },
      [types.SET_TOKEN](state, token) {
          state.token = token;
      },
      [types.SET_CLIENTID](state, clientId) {
          state.clientId = clientId;
      },
    }
};
