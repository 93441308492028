/**
 * 资讯
 * add 2020-08-18 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    addNews: (data) => Request.post(address.addNews, data),
    getNewsList: (data) => Request.get(address.getNewsList, data),
    getNewsCategorys: (data) => Request.get(`${address.getNewsCategorys}?langcode=${data.langcode || 'GB'}`, null),
    getNewsDetail: (data) => Request.get(address.getNewsDetail, data),
    getHotNewsList: (data) => Request.get(address.getHotNewsList, data),
}