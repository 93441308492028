<template>
    <a-config-provider :locale="zh_CN">
        <div id="app">
            <router-view/>
            <div class="scroll-to-top" v-if="showScrollToTop" @click="toTop"></div>
        </div>
    </a-config-provider>
</template>

<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    import moment from 'moment';
    import 'moment/locale/zh-cn';
    moment.locale('zh-cn');
	export default {
		name: "App",
		components: {
        },
        data(){
            return {
                zh_CN,
                showScrollToTop: false,
                jobJson: this.$store.getters.jobJson,
            }
        },
        methods: {
            toTop: function(){
                window.scrollTo(0, 0)
            },
            handleScroll (event) {
                if(this.showScrollToTop) {
                    if(window.pageYOffset <= 10) {
                        this.showScrollToTop = false
                    } 
                } else {
                    if(window.pageYOffset > 10) {
                        this.showScrollToTop = true
                    }
                }
            }
        },
        created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
        },
	};
</script>

<style lang="scss">
    @import "./style/common.scss";
    .swiper-pagination > .swiper-pagination-bullet {
        width: 20px;
        height: 4px;
        border-radius: 2px;
        background-color: #aca9a9;
        margin-right: 10px;
        
    }
    .swiper-pagination > .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background-color: #36C4D0;
    }
    
    .answer-list > .answer-item .answer-value > a {
        text-decoration: underline;
        line-height: 1.8;
    }

    .ant-radio-group {
        height: 32px;
        line-height: 32px;
        padding: 0px 10px;
    }

    #app {
        // font-size: $font-size-base;
        // color: $color-common;
        // font-family: -apple-system, BlinkMacSystemFont, "PingFang SC", "SF UI Text",
        // "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, Simsun, sans-serif;
        display: flex;
        width: 100%;
        min-height: 100%;
    }

        // 下拉菜单
    .ant-dropdown-menu {
        height: 168px;
        overflow-y: scroll;
    }
    .ant-input {
        border-color: #D6E7EB;
    }
    .ant-modal-body {
		padding: 0px;
	}

    .ant-modal {
        top: 50px;
    }

    .ant-input:hover,
    .ant-input:focus,
    .ant-cascader-picker:focus .ant-cascader-input {
        border-color: #36C4D0;
    }

    .gm-style .gm-style-iw-c {
        top: -35px;
    }

    .gm-style .gm-style-iw-t::after {
        top: -38px;
    }
    
    /* 回到顶部  start */
    .scroll-to-top {
        position: fixed;
        right: 40px;
        bottom: 100px;
        width: 50px;
        height: 50px;
        z-index: 999;
        padding: 10px;
        background-color: #ccc;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(./assets/imgs/mobile/img_top@2x.png);
        box-shadow: 2px 2px 5px #ccc;
        cursor: pointer;
    }

    /* 回到顶部  end */
</style>
