/**
 * 招聘信息
 * add 2022-06-26 by tuanhui
 */

import Request from '../common/request';
import address from './address'

export default {
    getJobList: (data) => Request.post(address.getJobList, data),
    getJobDetail: (data) => Request.post(address.getJobDetail, data),
    getEmploymentType: (data) => Request.get(address.getEmploymentType, data),
    getQualification: (data) => Request.get(address.getQualification, data),
    getWorkYears: (data) => Request.get(address.getWorkYears, data),
}